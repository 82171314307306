@layer base {
  .main {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
}

.content {
  --local-row-gap: var(--sk-space-32) !important;
  position: relative;
  z-index: var(--z-reset-in-the-current-stacking-context);
  padding: var(--sk-space-40) var(--sk-space-16);
}

@media (--sk-medium-viewport) {
  .content {
    padding: var(--sk-space-64) 0;
  }
}

.logos {
  display: flex;
  align-items: center;
  gap: var(--sk-space-16);
}

@media (--sk-medium-viewport) {
  .logos {
    justify-content: center;
  }
}

/* Signup */
.signup {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
}

@media (--sk-medium-viewport) {
  .signup {
    padding: var(--sk-space-48) var(--sk-space-32);
    border-radius: var(--sk-radius-16);
    background-color: var(--sk-color-white);
    box-shadow: 0 var(--sk-space-4) var(--sk-space-16) rgb(0 0 0 / 25%);
    gap: var(--sk-space-40);
  }
}

.arrow {
  display: none;
  pointer-events: none;
}

@media (--sk-large-viewport) {
  .arrow {
    display: initial;
    position: absolute;
    z-index: var(--z-down-in-the-current-stacking-context);
    right: 5%;
    bottom: 15%;
    rotate: -15deg;
  }
}

.signup .title {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
}

@media (--sk-large-viewport) {
  .signup .title {
    text-align: center;
  }
}

.form,
.dualFields {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
}

@media (--sk-medium-viewport) {
  .dualFields {
    flex-direction: row;
    gap: var(--sk-space-16);
  }

  .dualFields .field {
    width: 50%;
  }

  .dualFields .field input {
    width: 100%;
  }
}

.submitButton {
  width: 100%;
}

/* Login */
.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--sk-space-32);
}

.login .divider {
  display: inline-flex;
  align-self: center;
  width: var(--sk-space-40);
}

.login .title {
  display: flex;
  gap: var(--sk-space-8);
  justify-content: center;
}

.loginTerms {
  color: var(--sk-color-grey-600);
  text-align: center;
}

}