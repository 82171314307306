@layer base {
  .ccSectionHeader {
  display: flex;
  align-items: center;
  gap: var(--sk-space-12);
  margin-bottom: var(--sk-space-32);
}

.ccFiltersContainer {
  --cc-filters-gap: var(--sk-space-16);
  display: flex;
  flex-wrap: wrap;
  gap: 0 var(--cc-filters-gap);
  margin-top: var(--sk-space-24);
  margin-bottom: var(--sk-space-40);
}

.targetedJobsFilter,
.partnerJobsFilter,
.alumniJobsFilter {
  width: 100%;
}

@media (--sk-medium-viewport) {
  .targetedJobsFilter,
  .partnerJobsFilter,
  .alumniJobsFilter {
    width: calc(50% - calc(var(--cc-filters-gap) / 2));
  }
}

.section {
  margin-top: var(--sk-space-24);
}

.ccTagsFilter {
  margin: var(--sk-space-24) 0;
}

.divider {
  margin: var(--sk-space-32) 0 var(--sk-space-48);
}

}