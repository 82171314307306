@layer base {
  .main {
  --gap-size: var(--sk-space-8);
  display: flex;
  gap: var(--gap-size);
  flex-wrap: wrap;
}

.ccTag {
  max-width: calc(50% - calc(var(--gap-size) / 2));
}

.link {
  padding-top: var(--sk-space-4);
  padding-bottom: var(--sk-space-4);
}

}