@layer base {
  .labelContainer {
  display: flex;
  flex-direction: column;
}

.subLabel {
  color: var(--sk-color-grey-600);
}

.count {
  margin-left: var(--sk-space-8);
  color: var(--sk-form-checkbox-secondary-label-color-default-light);
  white-space: nowrap;
}

.labelContainer:hover .count {
  color: var(--sk-form-checkbox-secondary-label-color-hover-light);
}

.main:focus-within .count {
  color: var(--sk-form-checkbox-secondary-label-color-focus-light);
}

}