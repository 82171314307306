@layer base {
  .backdrop {
  position: fixed;
  z-index: var(--z-index-modal);
  inset: 0;
  height: 100dvh;
  animation: 0.3s ease-out 0s 1 show-backdrop;
  opacity: 0.4;
  background-color: var(--sk-color-black);
}

.backdrop___fullscreen {
  animation-name: show-modal;
  opacity: 1;
  background-color: var(--sk-color-white);
}

.wrapper {
  display: flex;
  position: fixed;
  z-index: var(--z-index-modal); /* above the backdrop because it is afterwards in the HTML */
  bottom: 0;
  left: 0;
  flex-direction: column;
  width: 100%;
  height: 100dvh;
  overflow-y: hidden;
  animation: 0.3s ease-out 0s 1 show-modal;
  background-color: var(--sk-color-white);
}

.wrapper__isLocked {
  pointer-events: none;
}

@media (--sk-medium-viewport) {
  .wrapper {
    top: 50%;
    right: inherit; /* reset mobile */
    bottom: inherit; /* reset mobile */
    left: 50%;
    max-width: 35.25rem; /* 564px */
    height: auto; /* reset mobile */
    max-height: 75vh; /* around 640px when the window height is 834px */
    transform: translate(-50%, -50%);
    border-radius: var(--sk-radius-24);
  }

  .wrapper___enlarged {
    max-width: 48rem;
    max-height: 90vh;
  }

  .wrapper___fullscreen {
    max-width: inherit;
    height: 100vh;
    max-height: inherit;
    border-radius: 0;
  }
}

.head {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: var(--sk-space-32);
  padding: 0 var(--sk-space-16);
  background-color: var(--sk-color-white);
}

.head___withSubtitle {
  align-items: flex-start;
}

@media (--sk-medium-viewport) {
  .head {
    padding: 0 var(--sk-space-32);
  }
}

.closeButton {
  margin-left: auto;
}

.wrapper___withoutHeader .closeButton {
  position: absolute;
  top: var(--sk-space-16);
  right: var(--sk-space-16);
}

@media (--sk-medium-viewport) {
  .wrapper___withoutHeader .closeButton {
    top: var(--sk-space-24);
    right: var(--sk-space-24);
  }

  .wrapper___fullscreen.wrapper___withoutHeader .closeButton {
    top: var(--sk-space-8);
    right: var(--sk-space-8);
  }
}

.content {
  flex: 1 1 auto;
  padding: 0 var(--sk-space-16);
  overflow-y: auto;
}

@media (--sk-medium-viewport) {
  .content {
    min-height: var(--sk-space-48);
    padding: var(--sk-space-32);
  }

  .subtitle {
    margin-top: var(--sk-space-8);
  }
}

.wrapper___withOverflow,
.wrapper___withOverflow .content {
  overflow-y: visible;
}

.wrapper___withoutHeader .content {
  margin-top: var(--sk-space-32);
}

@media (--sk-medium-viewport) {
  .wrapper___withoutHeader .content {
    margin-top: 0;
    padding-top: var(--sk-space-40);
  }
}

.foot {
  display: flex;
  justify-content: flex-end;
  padding: var(--sk-space-24) var(--sk-space-16) var(--sk-space-32);
  background-color: var(--sk-color-white);
  /* Shadow is --sk-color-grey-600 with 16% opacity */
  box-shadow: 0 var(--sk-space-4) var(--sk-space-16) 0 rgb(100 96 108 / 15%);
}

@media (--sk-medium-viewport) {
  .foot {
    padding: var(--sk-space-24) var(--sk-space-32) var(--sk-space-32);
  }
}

@keyframes show-modal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes show-backdrop {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.4;
  }
}

}