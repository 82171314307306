@layer base {
  .main {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
}

.title {
  text-align: left;
}

.content {
  position: relative;
  padding: var(--sk-space-24);
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-grey-050);
}

/* Field */
.field {
  display: none;
  text-align: center;
}

.field___visible {
  display: block;
}

.field___dragOver {
  background-color: var(--sk-color-grey-100);
}

.field .notice {
  margin-top: var(--sk-space-8);
  color: var(--sk-color-grey-600);
}

.field .uploadButton {
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
  width: 100%;
  margin-top: var(--sk-space-24);
}

/* This prevents to drop a file on the button */
.field___dragOver .uploadButton {
  z-index: var(--z-reset-in-the-current-stacking-context);
}

/* The input needs to be above the whole component to accept dropped files */
.field .uploadButton__input {
  position: absolute;
  z-index: var(--z-reset-in-the-current-stacking-context);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.field .error {
  margin-top: var(--sk-space-8);
  color: var(--sk-color-error-500);
}

/* Preview */
.preview {
  display: flex;
  align-items: center;
  outline: none;
  color: inherit;
  text-decoration: none;
}

.preview::after {
  content: "";
  position: absolute;
  z-index: var(--z-reset-in-the-current-stacking-context);
  inset: 0;
}

.preview:hover::after {
  border-radius: var(--sk-data-display-card-radius);
  outline: var(--sk-color-grey-200) 2px solid;
}

.preview:focus-visible::after {
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-200);

  border-radius: var(--sk-data-display-card-radius);
  outline: none;
  background: transparent;
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
}

.preview .description {
  margin-right: auto;
}

.preview .actions {
  display: flex;
  align-items: center;
  gap: var(--sk-space-8);
}

.preview .deleteButton {
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
  color: var(--sk-color-error-500);
}


}