@layer base {
  .main {
  --border-width: 1px;
  display: flex;
  align-items: center;
  padding: 4px var(--sk-space-16);
  border: var(--border-width) solid var(--sk-color-grey-200);
  border-radius: var(--sk-radius-12);
  background: var(--sk-color-white);
  gap: var(--sk-space-4);
}

.main:hover {
  background: var(--sk-color-grey-050);
  cursor: pointer;
}

.main:focus {
  outline: 2px solid var(--sk-color-grey-200);
}

.label {
  flex: 0 1 auto;
  overflow: hidden;
  font-family: var(--sk-font-families-poppins);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.count {
  flex-shrink: 0;
  color: var(--sk-color-grey-500);
}

.main--selected {
  border: var(--border-width) solid var(--sk-color-grey-900);
  background: var(--sk-color-grey-900);
  color: var(--sk-color-white);
}

.main--selected:hover {
  background: var(--sk-color-black);
  cursor: pointer;
}

}