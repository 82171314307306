@layer base {
  .footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media (--sk-small-viewport-only) {
  .footer {
    flex-direction: column-reverse wrap;
  }
}

.divider {
  margin: var(--sk-space-32) 0 var(--sk-space-48);
}

.filter:last-of-type {
  margin-bottom: var(--sk-space-32);
}
}